import SmartParkingImage from "../image/SmartParkingImage.jpg";
import FoodOrderWebsiteImage from "../image/FoodOrderWebsiteImage.jpg";
import voice2task from "../image/voice2task.png";
import synczone from "../image/synczone.jpg";

export const projectsData = [
  {
    id: 1,
    name: "Voice2Task",
    description:
      "A full-stack app for CRM. It makes a summary and a list of actions for follow-up after phone call. ",
    technologies: ["AWS", "MYSQL", "AI", "Docker", "Nginx"],
    githubLink: "https://github.com/heyanlu/Voice2Task",
    image: voice2task,
  },

  {
    id: 2,
    name: "SyncZone",
    description:
      "A mobile application that facilitates time zone synchronization and meeting scheduling. ",
    technologies: ["SwiftUI", "Swift", "Firebase"],
    githubLink: "https://github.com/heyanlu/SyncZone",
    image: synczone,
  },

  {
    id: 3,
    name: "Smart Parking",
    description:
      "A full-stack parking system designed to handle both customer and administrator interactions.",
    technologies: ["Java", "Spring Boot", "Postgre", "React"],
    githubLink: "https://github.com/heyanlu/Smart_Parking_II",
    image: SmartParkingImage,
  },
  {
    id: 4,
    name: "Restaurant Order Website",
    description:
      "A full-stack restaurant order website that enables in-store or online food ordering.",
    technologies: ["Express", "Node.js"],
    githubLink: "https://github.com/heyanlu/Restaurant-Order-Web-Application",
    image: FoodOrderWebsiteImage,
  },
];
