import React from 'react';
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { projectsData } from './projectsData';
import './Projects.css';

export const Projects = () => {
  return (
    <section id="projects">
      <h3>PROJECTS</h3>
      <Grid container spacing={5} justifyContent="center" style={{ marginTop: '1rem' }}>
        {projectsData.map((project) => (
          <Grid item key={project.id} xs={12} sm={6} md={3}>
            <Paper className="project-paper">
              <Box className="project-content">
                <Box className="project-image">
                  <img
                    src={project.image}
                    alt={project.name}
                    style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                  />
                </Box>
                <Box p={2} className="project-info">
                  <Typography variant="h6">{project.name}</Typography>
                  <Typography 
                    variant="body1" 
                    color="textSecondary" 
                  >{project.description}</Typography>
                  <Box mt={1}> 
                    <ul className="project-technologies" style={{ fontStyle: 'italic' }}>
                      <li>{project.technologies.join(' | ')}</li>
                    </ul>
                  </Box>
                </Box>
                <Box p={2}>
                  <Button 
                    variant="contained" 
                    href={project.githubLink} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="project-button"
                  >
                    View
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};
