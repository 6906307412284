import React from 'react'; 
import { Typography } from "@mui/material";



export const Footer = () => {
  return (
    <Typography variant="h6" sx={{
      mt: 10,
      mb: 2,
      mx: 3,
    }}>
      &copy; 2024 Yanlu He
    </Typography >
  )
}
