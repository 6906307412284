import React, { useState } from 'react';
import image1 from '../image/1.jpg';
import image2 from '../image/2.jpg';
import image3 from '../image/3.jpg';
import image4 from '../image/4.jpg';
import image5 from '../image/5.jpg';


import './About.css';

export const About = () => {
  const [currentSection, setCurrentSection] = useState(0);

  const sections = [
    {
      title: "Software Engineer",
      description: "As a Software Engineer, I am passionate about creating impactful software solutions and designing scalable cloud infrastructures that drive business growth. I have gained expertise in Full Stack, Frontend, and Backend engineering. ",
      skills: ["Programming", "System Design", "Problem-solving"],
      image: image1,
    },
    {
      title: "Global Learner",
      description: "As a Global Learner, I embrace diverse cultures and continuously adapt to new environments, deepening my knowledge and understanding in a connected world.",
      skills: ["Cultural Awareness", "Adaptability", "Continuous Learning"],
      image: image2,
    },
    {
      title: "Parent",
      description: "As a Parent, I nurture growth, balance responsibilities, and cultivate resilience. ",
      skills: ["Empathy", "Patience", "Handling Multiple Tasks"],
      image: image3,
    },
    {
      title: "Lawyer",
      description: "As a Lawyer, I provided legal consultations to companies and represented them in filing or responding to lawsuits. I also offered pro bono legal services to those in need.",
      skills: ["Critical Analysis", "Collaboration", "Problem-solving", "Social Responsibility"],
      image: image4,
    },
    {
      title: "Volunteer Teacher",
      description: "As a Volunteer Teacher in underprivileged areas, I guided students through their educational journeys, fostering a love for learning. My role involved not just imparting knowledge, but also mentoring students and creating a supportive environment for those in need.",
      skills: ["Communication", "Mentorship", "Organization"],
      image: image5,
    },
  ];

  return (
    <section id="about">
      <h3>ABOUT</h3>
      <div className="timeline-container">
        <div className="timeline">
          {/* "I'm" section */}
          <div className="timeline-group">
            <div className="timeline-header">I am...</div>
            {sections.slice(0, 3).map((section, index) => (
              <div
                key={index}
                className={`timeline-item ${currentSection === index ? 'active' : ''}`}
                onClick={() => setCurrentSection(index)}
              >
                <span className="timeline-marker"></span>
                <span className="timeline-label">{section.title}</span>
              </div>
            ))}
          </div>

          {/* "I was" section */}
          <div className="timeline-group">
            <div className="timeline-header">I was...</div>
            {sections.slice(3).map((section, index) => (
              <div
                key={index + 3} // Adjust the index for the past sections
                className={`timeline-item ${currentSection === (index + 3) ? 'active' : ''}`}
                onClick={() => setCurrentSection(index + 3)}
              >
                <span className="timeline-marker"></span>
                <span className="timeline-label">{section.title}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="content-display">
          {sections.map((section, index) => (
            <div
              key={index}
              className={`narrative-section ${currentSection === index ? 'active' : ''}`}
            >
              <img src={section.image} alt={section.title} className="section-image" />
              <div className="section-text">
                <h2>{section.title}</h2>
                <p>{section.description}</p>
                {section.skills && (
                  <>
                    <h4>Skills:</h4>
                    <p>{section.skills.join(', ')}</p>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
