import React from 'react';
import { Home } from './components/Home';
import { Projects } from './components/Projects';
import { About } from './components/About';
import { Experience } from './components/Experience';
import { Footer } from './components/Footer';
import { Contact } from './components/Contact';
import { Navbar } from './components/Navbar'; 
import { Stack } from '@mui/material'; 

import './App.css';

function App() {
  return (
    <div className="App">
      <header>
      <Navbar />
      </header>

      <main>
        <Stack
          spacing={{ xs: 6, sm: 8, md: 20 }}
          sx={{
            mt: { xs: 4, sm: 5, md: 12 },
            mx: { xs: 4, sm: 5, md: 12 },
          }}
        >
          <Home />
          <About />
          <Experience />
          <Projects />
          <Contact />
        </Stack>
      </main>
      <Footer />
    </div>
  );
}

export default App;
