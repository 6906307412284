import { useState } from "react";
import { Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";


const pages = ["Home", "About", "Experience", "Projects", "Contact"];

export const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <AppBar position="sticky" sx={{ background: "linear-gradient(to bottom right, #B3B6F2, #7E5EF2)", transition: "background 0.5s ease" }}>
      <Toolbar position="sticky" sx={{ justifyContent: "space-between" }}>
        <IconButton onClick={handleClick} size="large" edge="start" color="inherit">
          <MenuIcon sx={{ display: { xs: "initial", sm: "none" } }} />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {pages.map((page, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                scrollToSection(page.toLowerCase());
                handleClose();
              }}
            >
              {page}
            </MenuItem>
          ))}
        </Menu>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          {pages.map((page, index) => (
            <Button
              color="inherit"
              key={index}
              onClick={() => scrollToSection(page.toLowerCase())}
              sx={{
                "&:hover": {
                  "& span": {
                    color: "#FFFFFF", 
                  },
                },
              }}
            >
              <Typography variant="button" color="white" sx={{ fontWeight: "bold" }}>
                {page === "Projects" ? (
                  <span>{page}</span>
                ) : (
                  page
                )}
              </Typography>
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
