import React from 'react'; 
import { Typography } from '@mui/material';
import './Home.css'; 

export const Home = () => {
  return (
    <section id="home">
      <Typography variant="h7">HELLO THERE👋, I'M</Typography>
      <Typography
        variant="h1"
        sx={{ fontWeight: "bold", marginBottom: "2rem" }}
      >
        Yanlu
      </Typography>
      <Typography variant="h6" color="#A38DF2">
        SOFTWARE ENGINEER
      </Typography>
      <p>Python • C/C++ • Java • JavaScript • TypeScript • Swift</p>
      <p>Node • Express • React • SQL/MongoDB • HTML/CSS</p>
      <p>SwiftUI • React Native</p>
      <a
        className="download-container"
        target="_blank"
        href="https://drive.google.com/file/d/1R_f56_tUHmdFkHQLnr5n-r3iyutymJpC/view?usp=drive_link"
      >
        VIEW MY RESUME!
      </a>
    </section>
  );
}
