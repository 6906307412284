import React from "react";
import profileImage from "../image/my_photo.jpg";
import { GitHub, LinkedIn } from "@mui/icons-material";
import { Box, Paper, Typography } from "@mui/material";

import "./Contact.css";

export const Contact = () => {
  return (
    <section id="contact">
      <h3>CONTACT</h3>
      <Box className="main-wrapper">
        <Paper className="contact-box" elevation={12}>
          <div className="box-component">
            <img
              src={profileImage}
              alt="Yanlu's profile"
              className="portrait"
            />
          </div>
          <div className="box-component">
            <Typography variant="h5" sx={{ fontWeight: "bold", textAlign:"center" }} color="white">
              Yanlu He
            </Typography>
            <div className="email-container">
              <a
                href="mailto:he.yanl@northeastern.edu"
                style={{ color: "white" }}
              >
                he.yanl@northeastern.edu
              </a>
            </div>
            <div className="social-media">
              <a
                className="LinkedIn"
                href="https://www.linkedin.com/in/yanlu-he/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white" }}
              >
                <LinkedIn />
              </a>
              <a
                className="Github"
                href="https://github.com/heyanlu"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white" }}
              >
                <GitHub />
              </a>
            </div>
          </div>
        </Paper>
      </Box>
    </section>
  );
};
