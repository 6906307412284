import React from "react";
import workImage1 from "../image/logo1.png";
import workImage2 from "../image/logo2.jpg";
import workImage3 from "../image/logo3.png";

import "./Experience.css";

export const Experience = () => {
  const experiences = [
    {
      company: "A Round Entertainment",
      title: "Software Engineer Intern",
      location: "New York, NY, US",
      time: "Aug, 2024 - Present",
      description:
        "Created and enhanced mobile applications for iOS and Android, ensuring maximum speed, scalability, and responsiveness through continuous optimization and thorough testing.",
      image: workImage1,
    },
    {
      company: "Hangzhou Garden Landscape Society",
      title: "Web Developer Intern",
      location: "Hangzhou, ZJ, CN",
      time: "Jan, 2024 - Jun, 2024",
      description:
        "Designed and implemented RESTful APIs for local gardens in Hangzhou. Created the first website exclusively dedicated to local lotus garden, served 2,000 viewers since inception.",
      skills: ["Node.js", "Express", "MongoDB", "API Design"],
      image: workImage2,
    },
    {
      company: "Rise2Gether",
      title: "Web Developer Volunteer",
      location: "Seattle, Washington, US",
      time: "Jun, 2024 - Present",
      description:
        "Partnered with a team of developers and designers to update the website, adding necessary sections such as event schedules, blog posts, and member list, ensuring the content remains current and engaging.",
      image: workImage3,
    },
  ];

  return (
    <section id="work-experience">
      <h3>EXPERIENCE</h3>

      <div className="experience-container">
        {experiences.map((experience, index) => (
          <div key={index} className="experience-item">
            <img
              src={experience.image}
              alt={experience.company}
              className="experience-image"
            />
            <div className="experience-text">
              <h2>{experience.company}</h2>
              <h4>{experience.title}</h4>
              <h5>{experience.location}</h5>
              <h5>{experience.time}</h5>
              <p>{experience.description}</p>
              {experience.skills && (
                <>
                  <p>Skills: {experience.skills.join(", ")}</p>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
